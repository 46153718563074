import { handleException } from "../lib/handleApiErrorRespose";
import cookieCutter from "cookie-cutter";

export const postAction = async (pageId, payload) => {
  const url = `/api/page?id=${pageId}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
    });

    return await response.json();
  } catch (error) {
    return handleException(error);
  }
};

export const fetchMemberData = async (id) => {
  const memberUrl = `/api/member?id=${id}`;

  try {
    const response = await fetch(memberUrl);
    return await response.json();
  } catch (error) {
    return handleException(error);
  }
};

export const fetchPaymentMethod = async () => {
  const url = `/api/payment_methods`;
  try {
    const response = await fetch(url);
    return await response.json();
  } catch (error) {
    return handleException(error);
  }
};

export const memberValidate = async (pageId, payload) => {
  const url = `/api/page/validate?id=${pageId}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    return await response.json();
  } catch (error) {
    return handleException(error);
  }
};

export const postGoCardlessTransaction = (pageId, payload) => {
  const url = `/api/go_cardless/pages/${pageId}/start_flow?${payload}`;
  window.open(url);
};

export const postTransaction = async (pageId, payload) => {
  const url = `/api/transaction?id=${pageId}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
    });

    const { setCookie = null, ...data } = await response.json();
    if (setCookie) {
      const cookies = setCookie
        .split(", ")
        .filter((cookie) =>
          ["member_id", "payment_methods"].some((key) => cookie.includes(key))
        );
      cookies.forEach((cookie) => {
        const [nameValue] = cookie.split("; ");
        const [name, value] = nameValue.split("=");

        let expirationDate = new Date();
        cookieCutter.set(name, value, {
          expires: new Date(
            expirationDate.setFullYear(expirationDate.getFullYear() + 2)
          ),
          path: "/",
          domain: ".eko.org",
        });
      });
    }
    return data;
  } catch (error) {
    return handleException(error);
  }
};

export const postOneClickDonation = async (pageId, payload) => {
  const url = `/api/transaction/one_click?id=${pageId}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
    });

    return await response.json();
  } catch (error) {
    return handleException(error);
  }
};

export const updatePagePublishStatus = async (pageId, payload) => {
  const url = `/api/page/update?id=${pageId}`;

  try {
    const response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(payload),
    });
    return await response.json();
  } catch (error) {
    return handleException(error);
  }
};

export const pageStatus = async () => {
  const url = "/api/status/";

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    let responseBody = { published: false };
    if (response.status === 200) {
      responseBody = { published: true };
    }

    return responseBody;
  } catch {
    // We don't log the error on the client
  }
};

export const trackConversions = async (payload, isTrackingAccepted) => {
  // If tracking cookie is not accepted do not trigger event
  if (!isTrackingAccepted) {
    return { message: "Tracking not accepted. Conversion not tracked." };
  }

  const url = "/api/conversions";

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    return await response.json();
  } catch (error) {
    return handleException(error);
  }
};

export const getMemberCurrencyByCountry = async () => {
  const url = "/api/member/geo";

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    return await response.json();
  } catch (error) {
    return handleException(error);
  }
};
