export const GA4_FORM_SUBMIT = "form_submit";

export const GA4_PURCHASE = "purchase";

export const GA4_ADD_PAYMENT_INFO = "add_payment_info";

export const GA4_SELECT_ITEM = "select_item";

export const GA4_SHARE = "share";

export const GA4_BUTTON_CLICK = "button_click";

export const GA4_EXPERIMENT_VIEW = "experiment_viewed";

export const GA4_COVER_TRANSACTION_COST = "cover_transaction_cost";

export const EV_TRANSACTION_SUBMITTED = {
  category: "fundraising",
  eventName: "transaction_submitted",
};

export const FB_EV_INITIATE_CHECKOUT = "InitiateCheckout";
export const FB_EV_DONATE = "Donate";
export const FB_EV_PAYMENT_INFO = "AddPaymentInfo";
export const FB_EV_LEAD = "Lead";
export const FB_EV_COMPLETE_REGISTRATION = "CompleteRegistration";
export const FB_EV_POST_ACTION_DONATE_YES = "PostActionDonateYes";
